import _acorn2 from "acorn";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DynamicImportKey = undefined;

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _get = function () {
  function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);

      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return getter.call(receiver);
    }
  }

  return get;
}();

exports["default"] = dynamicImport;
var _acorn = _acorn2;

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
/* eslint-disable no-underscore-dangle */


var DynamicImportKey = exports.DynamicImportKey = "Import"; // NOTE: This allows `yield import()` to parse correctly.

_acorn.tokTypes._import.startsExpr = true;

function parseDynamicImport() {
  var node = this.startNode();
  this.next();

  if (this.type !== _acorn.tokTypes.parenL) {
    this.unexpected();
  }

  return this.finishNode(node, DynamicImportKey);
}

function parenAfter() {
  return /^(\s|\/\/.*|\/\*[^]*?\*\/)*\(/.test(this.input.slice(this.pos));
}

function dynamicImport(Parser) {
  return function (_Parser) {
    _inherits(_class, _Parser);

    function _class() {
      _classCallCheck(this, _class);

      return _possibleConstructorReturn(this, (_class.__proto__ || Object.getPrototypeOf(_class)).apply(this, arguments));
    }

    _createClass(_class, [{
      key: "parseStatement",
      value: function () {
        function parseStatement(context, topLevel, exports) {
          if (this.type === _acorn.tokTypes._import && parenAfter.call(this)) {
            return this.parseExpressionStatement(this.startNode(), this.parseExpression());
          }

          return _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), "parseStatement", this).call(this, context, topLevel, exports);
        }

        return parseStatement;
      }()
    }, {
      key: "parseExprAtom",
      value: function () {
        function parseExprAtom(refDestructuringErrors) {
          if (this.type === _acorn.tokTypes._import) {
            return parseDynamicImport.call(this);
          }

          return _get(_class.prototype.__proto__ || Object.getPrototypeOf(_class.prototype), "parseExprAtom", this).call(this, refDestructuringErrors);
        }

        return parseExprAtom;
      }()
    }]);

    return _class;
  }(Parser);
}

export default exports;
export const __esModule = exports.__esModule;
const _DynamicImportKey = exports.DynamicImportKey;
export { _DynamicImportKey as DynamicImportKey };